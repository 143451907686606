import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import toast from 'react-hot-toast';

class Api {
    constructor() {
        this.cookieStr = null;
        this.client = axios.create({
            baseURL: CONFIG.apiUrl,
            withCredentials: true,
        });

        this.client.interceptors.response.use(null, (err) => {
            console.error(err.response);

            captureException(err),
                {
                    extra: {
                        response: err.response,
                        request: err.config,
                    },
                };

            const userErrorMessage = err.response?.data?.error
                ? 'An unexpected error occurred. Please try again.'
                : err.response?.data?.message ||
                  err.message ||
                  'Something went wrong. Please try again.';

            toast.error(userErrorMessage);
            return Promise.reject(err);
        });
    }

    init(cookieStr) {
        this.cookieStr = cookieStr;
    }

    destruct() {
        this.cookieStr = null;
    }

    request(config) {
        const requestConfig = { ...config };
        // Add session cookie to request if on server
        // Adding cookies is not allowed or necessary on client
        if (typeof window === 'undefined' && this.cookieStr) {
            requestConfig.headers = {
                ...(requestConfig.headers || {}),
                cookie: this.cookieStr,
            };
        }

        if (CONFIG.debug) {
            const authenticated =
                requestConfig?.headers?.cookie.indexOf(
                    CONFIG.session.cookieName
                ) !== -1;
            console.log(
                `axios: ${requestConfig?.method || 'GET'} ${
                    requestConfig.url
                }, authenticated: ${authenticated ? 'yes' : 'no'}`
            );
        }

        return this.client.request(requestConfig);
    }
}

export default new Api();
